import React from 'react';
import Ctx from './ctx';
import * as Babel from '@babel/standalone';
import StringToReact from './strintToReact';
var getCtx = function getCtx(React, Babel, rerender) {
  return new Ctx(React, Babel, rerender);
};
export default StringToReact.bind(null, {
  getCtx: getCtx,
  Babel: Babel,
  react: React
});